<template>
    <div class="app-wrapper" ref="appWrapper">
        <ynk-layout>
            <router-view v-slot="{ Component }">
                <transition mode="out-in"
                            @enter="getAnimateIn"
                            @leave="getAnimateOut"
                            :css="false"
                            appear>
                    <component :is="Component" ref="currentView" />
                </transition>
            </router-view>
        </ynk-layout>
    </div>
</template>

<style lang="scss">
    @import './static/scss/app.scss';
</style>

<script>
    import { gsap } from '@/vendor';
    import Main from '@/app/layouts/Main';

    import { browsercheck } from './app/mixins';

    export default {
        name: 'App',

        components: {
            'ynk-layout': Main,
        },

        mixins: [
            browsercheck
        ],

        computed: {
            isMobile()
            {
                return this.$store.getters.isMobile;
            }
        },

        mounted()
        {
            window.addEventListener( 'resize', this.onResize );
            this.onResize();

            if( window.browsercheck.browser === 'safari' )
            {
                this.$refs.appWrapper.classList.add( 'safariBrowser' );
            }

            if( window.browsercheck.browser === 'firefox' )
            {
                this.$refs.appWrapper.classList.add( 'firefoxBrowser' );
            }

            if( window.browsercheck.platform === 'macos' && window.browsercheck.browser === 'chrome' )
            {
                this.$refs.appWrapper.classList.add( 'mac-chrome' );
            }
        },

        methods: {
            onResize()
            {
                document.documentElement.style.setProperty( '--vh100', window.innerHeight + 'px' ); // fix for 100vh on iphones

                if( window.innerWidth < 720 && !this.isMobile )
                {
                    this.$store.dispatch( 'setMobile', true );
                }
                else if( window.innerWidth >= 720 && this.isMobile )
                {
                    this.$store.dispatch( 'setMobile', false );
                }
            },

            getAnimateIn( el, done )
            {
                let tl = gsap.timeline({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateIn === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateIn(), 0 );
                }
                else
                {
                    tl.to({ foo: 0 }, { foo: 1, duration: 0.01 });
                }
            },

            getAnimateOut( el, done )
            {
                let tl = gsap.timeline({ onComplete: done });
                if( this.$refs.currentView && typeof this.$refs.currentView.getAnimateOut === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateOut(), 0 );
                }
                else
                {
                    tl.to({ foo: 0 }, { foo: 1, duration: 0.01 });
                }
            }
        }
    };
</script>
