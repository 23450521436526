<template>
    <div class="copy qr-section">
        <div :class="{ 'qr-reversed': QRonleft }" class="row m-center-in-middle">
            <div :class="{ 'm-col-offset-1': QRonleft, 'm-col-offset-0': !QRonleft }" class="col-10 col-offset-1 m-col-5">
                <h2>Bewegen via Whatsapp <br class="hide-for-small" />met Billy de chatbot</h2>
                <p>Kun je wel wat extra begeleiding gebruiken bij het bewegen?
                    Dan is er Billy de Chatbot. Op het moment dat het jou schikt
                    stuur je Billy het bericht 'Beweegminuut' via Whatsapp en
                    dan ga je van start! Van instructievideo's en tips tot beweegbingo
                    en webinars. Billy is als WhatsAppservice altijd beschikbaar.
                </p>
            </div>
            <div :class="{ 'm-col-offset-1': !QRonleft }" class="col-12 m-col-6 whatsapp">
                <img src="/static/images/qr-holder.png" class="hide-for-small" alt="Scan de QR-code om je aan te melden voor de WhatsApp chatbot. Je ontvangt berichten die je helpen om in beweging te blijven.">
                <a href="https://wa.me/31620877324" target="_blank" alt="Maak via WhatsApp gebruik van Billy de chatbot" class="hide-for-medium-up button"><img src="/static/images/whatsapp-brands.png" class="whatsapp-logo"> Chat met Billy</a>
            </div>
        </div>

        <v-shape v-if="!isMobile" type="zigzag-blue" top="50%" left="-3rem" width="5rem"></v-shape>
        <v-shape v-if="!isMobile" type="square" top="80%" left="11%" width="3rem"></v-shape>
        <v-shape v-if="!isMobile" type="square-hollow" top="0%" left="49%" width="5rem"></v-shape>
        <v-shape v-if="!isMobile" type="zigzag-pink" top="65%" left="40%" width="5rem" rotation="140"></v-shape>
        <v-shape v-if="!isMobile" type="triangle-circle" top="100%" right="4rem" width="5rem" rotation="-10"></v-shape>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import Shape from '@/app/components/Shape';

    export default {
        components: {
            'v-shape': Shape
        },

        props: {
            QRonleft: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            isMobile()
            {
                return this.$store.getters.isMobile;
            }
        }
    };
</script>
