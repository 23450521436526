import { gsap } from '@/vendor';
import { createRouter, createWebHistory } from 'vue-router';
import Landing from '@/app/pages/Landing';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'Landing',
            component: Landing
        },

        {
            path: '/doe-mee',
            name: 'DoeMee',
            component: () =>
            {
                return import( /* webpackChunkName: "doe-mee" */ '@/app/pages/DoeMee' );
            }
        },

        {
            path: '/contact',
            name: 'Contact',
            component: () =>
            {
                return import( /* webpackChunkName: "contact" */ '@/app/pages/Contact' );
            }
        },

        {
            path: '/disclaimer',
            name: 'Disclaimer',
            component: () =>
            {
                return import( /* webpackChunkName: 'disclaimer' */ '@/app/pages/Disclaimer' );
            }
        }
    ]
});

router.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    gsap.set( 'html, body', { scrollTop: 0, duration: 0.5, ease: 'sine.inOut' });

    next();
});

export default router;
