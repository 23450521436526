<template>
    <div :class="{ 'main-layout': true, 'prevent-scroll': isMenuOpen }">
        <nav class="nav">
            <img :class="{ 'nav-logo': true, 'hide-for-small': true }" @click="returnToHome" src="/static/images/beweegminuut-logo.png" alt="Logo De Nationale Beweegminuut" />
            <div :class="{ 'mobile-block': true, 'is-open': isMenuOpen }">
                <div class="top-row">
                    <img class="logo" src="/static/images/beweegminuut-logo.png" @click="returnToHome" alt="Logo De Nationale Beweegminuut" />
                    <div class="hamburger" @click="toggleOpen">
                        <img v-if="!isMenuOpen" src="/static/images/hamburger.png" />
                        <img v-else src="/static/images/close.png" />
                    </div>
                </div>

                <transition mode="out-in"
                            @enter="navAnimateIn"
                            @leave="navAnimateOut"
                            :css="false">
                    <div class="link-box-mobile" v-show="isMenuOpen">
                        <router-link @click="forceClose" alt="De startpagina" to="/"><p>Home</p></router-link>
                        <router-link @click="forceClose" alt="Doe op verschillende manieren mee met De Nationale Beweegminuut" to="/doe-mee"><p>Doe mee!</p></router-link>
                        <router-link @click="forceClose" alt="Neem contact op" to="/contact"><p>Contact</p></router-link>

                        <v-shape type="cursor-purple" top="20%" left="2rem" width="3rem"></v-shape>
                        <v-shape type="circle-half" top="0%" right="2rem" width="3rem"></v-shape>
                        <v-shape type="square-hollow" top="35%" right="-0.5rem" width="2rem" rotation="-35"></v-shape>
                        <v-shape type="square-hollow" top="85%" right="10%" width="3rem"></v-shape>
                        <v-shape type="zigzag-blue" top="85%" left="10%" width="5rem"></v-shape>
                        <v-shape type="square" top="120%" left="15%" width="3rem"></v-shape>
                        <v-shape type="zigzag-purple" top="120%" left="60%" width="4rem"></v-shape>
                    </div>
                </transition>
            </div>

            <div class="row hide-for-small">
                <div class="col-12">
                    <div class="link-box">
                        <router-link to="/" alt="De startpagina" ><p>Home</p></router-link>
                        <router-link to="/doe-mee" alt="Doe op verschillende manieren mee met De Nationale Beweegminuut" ><p>Doe mee!</p></router-link>
                        <router-link to="/contact" alt="Neem contact op" ><p>Contact</p></router-link>
                    </div>
                </div>
            </div>
            <ul class="breadcrumb">
                <li>
                    <a href="/">Home</a>
                    <ul v-if="$route.path !== '/'">
                        <li>
                            <a :href="$route.path">{{ $route.path === '/doe-mee' ? 'Doe mee!' : 'Contact' }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>

        <div class="content">
            <slot></slot>
        </div>

        <v-hr>
            <div class="hr-with-logo">
                <img src="/static/images/beweegminuut-logo.png" alt="Logo De Nationale Beweegminuut" />
            </div>
        </v-hr>

        <footer class="footer">
            <div class="address-content">
                <p><strong>Adresgegevens</strong></p>
                <p>Ministerie van Sociale Zaken en <br class="hide-for-small" />Werkgelegendheid</p>
                <p>De Nationale Beweegminuut is een initiatief van het Ministerie van Sociale Zaken en Werkgelegenheid en onderdeel van de campagne &lsquo;Zet ook de stap&rsquo;.</p>

                <a href="https://zetookdestap.nl" target="_blank" alt="Website van de organisatie">www.zetookdestap.nl <span class="screen-reader-only">(opent in nieuw venster)</span></a><br/>
                <a href="mailto:zetookdestap@bkb.nl" target="_blank" alt="E-mailadres van de organisatie">zetookdestap@bkb.nl <span class="screen-reader-only">(opent in nieuw venster)</span></a>
            </div>

            <div class="white-bar">
                <div class="row align-center">
                    <div class="col-12 m-col-6">

                        <img class="msz-logo" src="/static/images/msz-logo.png" />

                        <img class="zods-logo" src="/static/images/zods-logo.png" />

                    </div>

                    <div class="col-12 m-col-6 text-right hide-for-small">
                        <a class="disclaimer" href="/static/pdf/disclaimer_denationalebeweegminuut.pdf" target="_blank" alt="Disclaimer van De Nationale Beweegminuut">Disclaimer <span class="screen-reader-only">(opent in nieuw venster)</span></a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 hide-for-medium-up">
                        <a class="disclaimer" href="/static/pdf/disclaimer_denationalebeweegminuut.pdf" target="_blank" alt="Disclaimer van De Nationale Beweegminuut">Disclaimer <span class="screen-reader-only">(opent in nieuw venster)</span></a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import { gsap } from '@/vendor';

    import HrElem from '@/app/components/HrElem';
    import Shape from '@/app/components/Shape';

    export default {
        components: {
            'v-hr': HrElem,
            'v-shape': Shape
        },

        data()
        {
            return {
                isMenuOpen: false
            };
        },

        computed: {
            isMobile()
            {
                return this.$store.getters.isMobile;
            }
        },

        watch: {
            $route: function()
            {
                this.forceClose();
            },

            'isMobile': function( pVal )
            {
                if( !pVal )
                {
                    this.forceClose();
                }
            }
        },

        mounted()
        {
        },

        methods: {
            forceClose()
            {
                this.isMenuOpen = false;
            },

            toggleOpen()
            {
                this.isMenuOpen = !this.isMenuOpen;
            },

            navAnimateIn( el, done )
            {
                this.inAnim = gsap.timeline({ onComplete: done });
                this.inAnim.fromTo( '.link-box-mobile' , { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.3, display: 'flex' }, 0.5 );
            },

            navAnimateOut(  el, done )
            {
                if( this.inAnim.isActive() )
                {
                    this.inAnim.kill();
                }
                this.outAnim = gsap.timeline({ onComplete: done });
                this.outAnim.fromTo( '.link-box-mobile' , { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.2, display: 'none' }, 0 );
            },

            returnToHome()
            {
                this.$router.push( '/' );
            }
        }
    };
</script>
