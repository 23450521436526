<template>
    <div class="copy qr-section beweegtips">
        <div :class="{ 'qr-reversed': QRonleft }" class="row m-center-in-middle">
            <div :class="{ 'm-col-offset-1': QRonleft, 'm-col-offset-0': !QRonleft }" class="col-10 col-offset-1 m-col-5">
                <h2>Ontvang dagelijkse beweegtips via&nbsp;Whatsapp</h2>

                <p>Vergeet jij soms om 11.00 uur te bewegen? Meld je dan aan voor de dagelijkse beweegtips via Whatsapp. Elke werkdag stipt om 11.00&nbsp;uur ontvang je van het team van De&nbsp;Nationale&nbsp;Beweegminuut een beweegtip. Scan deze QR&#8209;code en app 'Alert&nbsp;AAN'.
                </p>
            </div>
            <div :class="{ 'm-col-offset-1': !QRonleft }" class="col-12 m-col-6 whatsapp">
                <img src="/static/images/qr-holder-beweegtips.png" class="hide-for-small" alt="Scan de QR-code om je aan te melden voor de dagelijkse beweegtips via WhatsApp. Je ontvangt berichten die je helpen om in beweging te blijven.">
                <a href="https://api.whatsapp.com/send/?phone=31620877324&text=alert%20aan" target="_blank" alt="Krijg via Whatsapp dagelijkse beweegtips" class="hide-for-medium-up button"><img src="/static/images/whatsapp-brands.png" class="whatsapp-logo"> Dagelijkse beweegtips</a>
            </div>
        </div>

        <v-shape v-if="!isMobile" type="zigzag-blue" top="50%" left="-3rem" width="5rem"></v-shape>
        <v-shape v-if="!isMobile" type="square" top="80%" left="11%" width="3rem"></v-shape>
        <v-shape v-if="!isMobile" type="square-hollow" top="0%" left="49%" width="5rem"></v-shape>
        <v-shape v-if="!isMobile" type="zigzag-pink" top="65%" left="40%" width="5rem" rotation="140"></v-shape>
        <v-shape v-if="!isMobile" type="triangle-circle" top="100%" right="4rem" width="5rem" rotation="-10"></v-shape>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import Shape from '@/app/components/Shape';

    export default {
        components: {
            'v-shape': Shape
        },

        props: {
            QRonleft: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            isMobile()
            {
                return this.$store.getters.isMobile;
            }
        }
    };
</script>
