<template>
    <div class="hero-section">
        <div :class="{ 'video': true, 'yt-vid': !poster }" @click.capture="hidePoster">
            <!-- <iframe v-if="poster" ref="iframe" width="1920" height="1080" src="https://www.youtube.com/embed/NJ09zBtGUsg?autoplay=1&controls=0&playsinline=1&modestbranding=1&muted=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

            <!-- <iframe @click.prevent="hidePoster" ref="iframe" src="https://player.vimeo.com/video/681392277?h=fd213de01a" width="1920" height="1080" frameborder="0" allowfullscreen ></iframe> -->

            <video class="vid" ref="vid" playsinline controls @click="hidePoster" onloadstart="this.volume=0.5">
                <source src="/static/videos/beweegminuut.mp4" type="video/mp4">
                <track label="Dutch" kind="subtitles" srclang="nl" src="/static/videos/beweegminuut_subs.vtt" default>
            </video>

            <img class="poster-vid" v-if="poster" src="/static/videos/hero.gif" />


        </div>

        <v-shape v-if="!isMobile" :hide="heroVidIsPlaying" type="triangle-circle" top="50%" left="3rem" width="5rem"></v-shape>
        <v-shape v-if="!isMobile" :hide="heroVidIsPlaying" type="zigzag-blue" top="70%" left="2rem" width="5rem"></v-shape>
        <v-shape v-if="!isMobile" :hide="heroVidIsPlaying" type="cursor-blue" top="65%" left="10rem" width="5rem"></v-shape>
        <v-shape v-if="!isMobile" :hide="heroVidIsPlaying" type="square" top="78%" left="8.5rem" width="3rem"></v-shape>
    </div>
</template>

<style lang="scss" scoped>
    .video{
        position:relative;
        height:100%;
        width:100%;
        display:block;

        video{
            display:block;
            margin:0 auto;
        }

        iframe{
            height:100%;
            position:absolute;
            top:50%;
            left:50%;

            transform:translate(-50%, -50%);
        }

        .poster-vid{
            pointer-events:none;
        }
    }
</style>

<script>
    // import Player from '@vimeo/player';
    import Shape from '@/app/components/Shape';

    export default {
        components: {
            'v-shape': Shape
        },

        data()
        {
            return {
                poster: true,
                shapesVisible: true,
                vidDuration: null
            };
        },

        computed: {
            isMobile()
            {
                return this.$store.getters.isMobile;
            },

            heroVidIsPlaying()
            {
                return false;
                // return this.$store.getters.heroVideoIsPlaying;
            }
        },

        watch: {
            poster: function( pVal )
            {
                if( pVal === false )
                {
                    // this.player.play();
                }
            }
        },

        mounted()
        {
            this.$refs.vid.addEventListener( 'play', this.hidePoster );
            this.$refs.vid.addEventListener( 'ended', this.showPoster );


            // setTimeout( () =>
            // {
            //     this.showShapes = false;
            // }, 2000 );

            // this.player = new Player( this.$refs.iframe );

            // this.player.getDuration().then( duration =>
            // {
            //     this.vidDuration = duration;
            // });

            // this.vidStartRef = this.vidStart;
            // this.vidEndRef = this.vidEnd;

            // this.player.on( 'play', this.vidStartRef );
            // this.player.on( 'pause', this.vidStartRef );
            // this.player.on( 'ended', this.vidEndRef );
        },

        beforeUnmount()
        {
            // this.player.off( 'play', this.storePlaystateRef );
            // this.player.off( 'ended', this.storePlaystateRef );
        },

        methods: {
            async vidStart()
            {
                this.handlePlaystate( true );
                this.hidePoster();

                // this.currentTime = await this.player.getCurrentTime();
                // console.log( 'current time:', this.currentTime );
                // this.vidTimeout = setTimeout( () =>
                // {
                //     this.player.pause();
                //     this.vidEnd();
                // }, ( this.vidDuration - this.currentTime ) * 1000 );
            },

            vidEnd()
            {
                this.handlePlaystate( false );
            },

            handlePlaystate( isPlaying )
            {
                this.$store.dispatch( 'heroVideoIsPlaying', isPlaying );
            },

            hidePoster()
            {
                this.poster = false;
            },

            showPoster()
            {
                this.poster = true;
            }
        }
    };
</script>
