<template>
    <div class="copy-section">
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {

    };
</script>
