<template>
    <div class="landing page">
        <v-hero></v-hero>

        <v-hr class="opteller-hr">
            <v-opteller class="hide-for-medium-up" ref="opteller"></v-opteller>
        </v-hr>

        <v-copy-section>
            <div class="row align-center">
                <div class="col-12 m-col-6">
                    <h1>Elke werkdag om&nbsp;11:00&nbsp;uur <br class="hide-for-medium-up" />één minuut bewegen</h1>
                    <p>Attentie zitwerkend Nederland! Zitten is niet goed voor ons. Daarom is er iedere werkdag om 11 uur:
                        De Nationale Beweegminuut. Voor alle kantoortijgers en sturologen, stap uit die vrachtwagen en aan
                        de kant met dat bureau. Ga lekker staan en steel de show! Klik op de knop <span class="hide-for-small">rechts</span><span class="hide-for-medium-up">hierboven</span> om mee te doen, en
                        help Nederland van Europees kampioen zitten naar Europees kampioen bewegen tijdens het werk!</p>

                    <v-calendar></v-calendar>
                </div>

                <div class="hide-for-small m-col-6">
                    <v-opteller ref="opteller"></v-opteller>
                </div>
            </div>

            <v-shape v-if="!isMobile" type="square" width="2rem" top="0%" left="25%" rotation="45"></v-shape>
            <v-shape v-if="!isMobile" type="circle-yellow" width="3rem" top="25%" left="1rem" rotation="240"></v-shape>
            <v-shape v-if="!isMobile" type="square" width="1rem" top="70%" left="1rem"></v-shape>
            <v-shape v-if="!isMobile" type="cursor-blue" width="3rem" top="100%" left="25%" rotation="-110"></v-shape>
        </v-copy-section>

        <!-- 4 strepen - You Got To Move! -->

        <v-hr class="with-text">
            <div class="triangle-bg">
                <h3>You got <br/>to move</h3>
            </div>
        </v-hr>

        <v-form-section class="squiggle-bg"></v-form-section>

        <v-copy-section class="bewegenbelangrijk">
            <div class="row reverse-on-mobile align-center">
                <div class="col-12 m-col-5">
                    <v-shape class="static" v-if="!isMobile" type="zigzag-pink"></v-shape>
                    <div class="row">
                        <div class="col-12">
                            <h2>Waarom is bewegen tijdens het werk zo belangrijk?</h2>
                            <p>In een ideale wereld zijn we ook tijdens ons werk gezond bezig. Toch zitten we in Nederland gemiddeld
                                10 uur op een werkdag. Daardoor lopen we meer kans op ziektes zoals hart- en vaatziekten, diabetes type II,
                                depressie, kanker en zelfs vroegtijdig overlijden. Beeldschermwerkers hebben daarnaast ook een verhoogde
                                kans op klachten aan armen, nek en schouders. Gelukkig is de oplossing vrij simpel: regelmatig even opstaan
                                en bewegen. Als je elk half uur beweegt, blijf je langer gezond. Begin met één minuut en probeer die zo vaak
                                mogelijk te herhalen op een werkdag!</p>
                        </div>
                    </div>
                    <a href="https://www.arboportaal.nl/campagnes/zet-ook-de-stap/wat-is-lichamelijke-onderbelasting" target="_blank" alt="Externe link: Kom meer te weten over het belang van bewegen tijdens werk" class="button" rel="noopener">Meer weten over lichamelijke onderbelasting<span class="screen-reader-only">(opent in nieuw venster)</span></a>
                    <v-shape class="static" type="zigzag-pink"></v-shape>
                </div>
                <div class="col-12 m-col-6 m-col-offset-1">
                    <img src="static/images/olga-de-nationale-beweegminuut.png" alt="Olga Commandeur doet een van de bewegingen van De Nationale Beweegminuut voor in jaren tachtig kleding.">
                </div>
            </div>
        </v-copy-section>

        <v-copy-section>
            <div class="row reverse-on-mobile align-center">
                <div class="col-12 m-col-6">
                    <div class="video">
                        <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/lbEtDCeFApw?modestbranding=1&controls=1&playsinline=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

                        <video controls preload="metadata" poster="/static/images/beweegminuut_instructievideo_1_poster.jpg" onloadstart="this.volume=0.5">
                            <source src="/static/videos/beweegminuut_instructievideo_1.mp4" type="video/mp4">
                            <track label="Dutch" kind="subtitles" srclang="nl" src="/static/videos/beweegminuut_instructievideo_1_subs.vtt" default>
                        </video>
                    </div>
                </div>
                <div class="col-12 m-col-6 instructievideo-kantoorwerkers">
                    <h2>Instructie&shy;video <br/>voor kantoor- en thuis&shy;werkers</h2>
                    <p>Werk je op kantoor en zit je veel? Bekijk dan snel deze korte routine van vitaliteitscoach Olga Commandeur, met drie bewegingen voor thuis of op kantoor. Zweten is niet vereist, plezier wel.</p>
                </div>
            </div>
            <br />
            <div class="row reverse-on-mobile align-center">
                <div class="col-12 m-col-6">
                    <div class="video">
                        <video controls preload="metadata" poster="/static/images/beweegminuut_instructievideo_2_poster.jpg" onloadstart="this.volume=0.5">
                            <source src="/static/videos/beweegminuut_instructievideo_2.mp4" type="video/mp4">
                            <track label="Dutch" kind="subtitles" srclang="nl" src="/static/videos/beweegminuut_instructievideo_2_subs.vtt" default>
                        </video>
                    </div>
                    <a href="https://www.arboportaal.nl/campagnes/zet-ook-de-stap/toolbox" target="_blank" alt="Meer instructievideo's om te bewegen tijdens werk" class="button workout-clickout">Meer tips om vaker te bewegen <span class="screen-reader-only">(opent in nieuw venster)</span></a>
                </div>
                <div class="col-12 m-col-6 instructievideo-chauffeurs">
                    <h2>Instructie&shy;video <br/>voor chauffeurs</h2>
                    <p>Ben je beroepschauffeur? Dan is het vast lastig om geregeld in beweging te blijven. Daar heeft vitaliteitscoach Olga Commandeur wat op bedacht: een makkelijke routine van drie bewegingen voor in de pauzes. Start de video!</p>
                </div>
            </div>
        </v-copy-section>

        <v-hr></v-hr>

        <v-socialphoto></v-socialphoto>

        <v-hr></v-hr>

        <v-qr :QRonleft="false"></v-qr>

        <v-hr></v-hr>

        <v-qr-beweegtips :QRonleft="false"></v-qr-beweegtips>
    </div>
</template>

<style lang="scss">
</style>

<script>
    import { gsap } from '@/vendor';

    import Hero from '@/app/sections/Hero';
    import Copy from '@/app/sections/Copy';
    import Form from '@/app/sections/Form';
    import QR from '@/app/sections/QR';
    import QRBeweegtips from '@/app/sections/QR-beweegtips';
    import Socialphoto from '@/app/sections/Socialphoto';

    import Opteller from '@/app/components/Opteller';
    import Shape from '@/app/components/Shape';
    import HrElem from '@/app/components/HrElem';
    import Calendar from '@/app/components/Calendar';

    export default {
        components: {
            'v-hero': Hero,
            'v-copy-section': Copy,
            'v-qr': QR,
            'v-qr-beweegtips': QRBeweegtips,
            'v-form-section': Form,
            'v-hr': HrElem,
            'v-opteller': Opteller,
            'v-shape': Shape,
            'v-calendar': Calendar,
            'v-socialphoto': Socialphoto
        },

        mounted()
        {

        },

        computed: {
            isMobile()
            {
                return this.$store.getters.isMobile;
            }
        },

        methods: {
            getAnimateIn()
            {
                let tl = gsap.timeline();

                // tl.add( this.$refs.opteller.inAnim(), 2 );
                tl.to({ foo: 0 }, { foo: 1, duration: 3 });

                return tl;
            }
        }
    };
</script>
