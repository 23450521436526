<template>
    <form @submit.prevent="onSubmit" title="registration-form">
        <div class="row">
            <div class="col-12">
                <input :class="{ error: errors.emailaddress }" v-bind="formConfig.emailaddress" v-model="entry.emailaddress" />
                <span class="error" v-if="errors.emailaddress">{{ errors.emailaddress[0] }}</span>
                <span class="error" v-if="duplicateEmailError">Dit e-mailadres staat al ingeschreven.</span>
            </div>
        </div>

        <div class="row">
            <div class="col-12 m-col-6">
                <div class="select-wrapper">
                    <select v-model="entry.sector" :class="{ error: errors.sector }" :placeholder="formConfig.sector.placeholder" :tabindex="formConfig.sector.tabindex" :name="formConfig.sector.name">
                        <option value="" disabled>{{ formConfig.sector.placeholder }}</option>
                        <option v-for="option in formConfig.sector.options" :value="option.value" :selected="option.name === entry.sector">{{ option.name }}</option>
                    </select>
                    <span class="error" v-if="errors.sector">{{ errors.sector[0] }}</span>
                </div>
            </div>

            <div class="col-12 m-col-6">
                <div class="select-wrapper">
                    <select v-model="entry.frequency" :class="{ error: errors.frequency }" :placeholder="formConfig.frequency.placeholder" :tabindex="formConfig.frequency.tabindex">
                        <option value="" disabled>{{ formConfig.frequency.placeholder }}</option>
                        <option v-for="option in formConfig.frequency.options" :value="option.value" :selected="option.name === entry.sector">{{ option.name }}</option>
                    </select>
                    <span class="error" v-if="errors.frequency">{{ errors.frequency[0] }}</span>
                </div>
            </div>
        </div>

        <div class="row flex">
            <div class="col-12 m-col-6">
                <div class="align-top checkbox-wrapper">
                    <input v-bind="formConfig.kit2" v-model="entry.kit2" />
                    <div class="indicator"></div>
                    <label :for="formConfig.kit2.id">Ik meld me aan voor de werkdagelijkse beweegtip om 11:00, tussen 12 en 28 september 2022</label>
                </div>
            </div>
            <div class="col-12 m-col-6">
                <div class="align-top checkbox-wrapper">
                    <input :class="{ error: errors.agree }" v-bind="formConfig.agree" v-model="entry.agree" />
                    <div class="indicator"></div>
                    <label :for="formConfig.agree.id">Ik ga akkoord met de <a tabindex="-1" href="/static/pdf/disclaimer_denationalebeweegminuut.pdf" target="_blank">algemene&nbsp;voorwaarden</a></label>
                </div>
                <span class="error checkbox-error" v-if="errors.agree">{{ errors.agree[0] }}</span>
            </div>
        </div>

        <div class="row flex">
            <div class="col-12 m-col-6">
                <div class="align-top checkbox-wrapper">
                    <input v-bind="formConfig.kit" v-model="entry.kit" />
                    <div class="indicator"></div>
                    <label :for="formConfig.kit.id">Ik meld me aan voor de nieuwsbrief met meer beweegtips en inspiratie</label>
                </div>
            </div>
            <div class="col-12 m-col-6">
            </div>
        </div>

        <div class="row">
            <div clas="col-12">
                <button type="submit" alt="Bevestig je aanmelding voor het Beweegminuut record" class="white" tabindex="6">Aanmelden <v-spinner v-if="waitingForApi" stroke="#fff"></v-spinner></button>
            </div>
        </div>
    </form>
</template>

<style lang="scss" scoped>
    label, input, span{
        display:block;
        width:100%;
    }

    label[for^="agree"], label[for^="kit"], input[type="radio"], input[type="checkbox"]{
        display:inline-block;
        width:auto;
    }
</style>

<script>
    // import { Axios } from '@/vendor';
    import Spinner from '@/app/components/Spinner';
    import { formConfig, fingerprintjs2, recaptcha } from '@/app/mixins';

    export default {
        components: {
            'v-spinner': Spinner
        },

        mixins: [ formConfig, fingerprintjs2, recaptcha ],

        data()
        {
            return {
                entry: {
                    emailaddress: '',
                    sector: '',
                    frequency: '',
                    kit: false,
                    kit2: false,
                    agree: false
                },

                waitingForApi: false,
                apiErrors: false,
                duplicateEmailError: false,

                validatedOnce: false,

                errors: {

                }
            };
        },

        computed: {
            savedEntry()
            {
                if( this.$store.getters[ 'entry/all' ] )
                {
                    return this.$store.getters[ 'entry/all' ];
                }

                return false;
            }
        },

        watch: {
            entry: {
                deep: true,
                handler()
                {
                    if( this.validatedOnce )
                    {
                        this.validate();
                    }
                }
            }
        },

        mounted()
        {
            // load entry if there is any
            if( this.savedEntry )
            {
                this.entry = Object.assign( this.entry, this.savedEntry );
            }
        },

        methods: {
            async validate()
            {
                this.validatedOnce = true;

                if( this.waitingForApi )
                {
                    return;
                }

                return this.validationSchema.validate( this.entry, { abortEarly: false })
                    .then( () =>
                    {
                        this.errors = {};
                    })
                    .catch( error =>
                    {
                        for( let item in this.entry )
                        {
                            this.errors[ item ] = [];
                        }

                        for( let err of error.inner )
                        {
                            if( !this.errors[ err.path ] )
                            {
                                this.errors[ err.path ] = [];
                            }

                            this.errors[ err.path ].push( err.errors[ 0 ] );
                        }

                        for( let item in this.errors )
                        {
                            if( this.errors[item].length === 0 )
                            {
                                delete this.errors[item];
                            }
                        }

                        this.$forceUpdate();

                        // this.scrollToError();
                    });
            },

            async onSubmit()
            {
                if( this.waitingForApi )
                {
                    return;
                }

                this.apiErrors = false;
                this.duplicateEmailError = false;
                this.errors = {};

                await this.validate();

                if( Object.keys( this.errors ).length === 0 )
                {
                    this.entry['origin'] = window._rootData.r;
                    if( window.location.href.indexOf( '192.168' ) === -1 )
                    {
                        this.entry['g-recaptcha-response'] = await this.executeRecaptcha();
                    }

                    this.waitingForApi = true;

                    this.$store.dispatch( 'entry/save', this.entry )
                        .then( result =>
                        {
                            this.waitingForApi = false;
                            this.$emit( 'formComplete', result ); // ?
                        })
                        .catch( errors =>
                        {
                            this.waitingForApi = false;
                            this.apiErrors = true;

                            if( errors.errors && errors.errors.emailaddress[0] === 'The emailaddress has already been taken.' )
                            {
                                this.duplicateEmailError = true;
                            }
                        });
                }
            }
        }
    };
</script>
