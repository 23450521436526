<template>
    <div class="hr-elem">
        <div class="slot-wrapper">
            <slot>
            </slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {

    };
</script>
