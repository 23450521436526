<template>
    <div class="opteller-wrapper">
        <div class="bol">
            <div class="bg"></div>
            <span class="label"><strong>Recordstand:</strong></span>
            <span :class="{ 'num-registrations': true, 'big-number': ( numDisplay > 100000 ) }">{{ dottify( numDisplay ) }}</span>
            <router-link to="/doe-mee" class="link button" alt="Registreer je hier voor het Beweegminuut record">Ik doe mee <span class="screen-reader-only">aan de Nationale Beweegminuut</span>!</router-link>
        </div>

        <v-shape v-if="!isMobile" type="zigzag-blue" width="5rem" bottom="60%" right="80%"></v-shape>
        <v-shape v-if="!isMobile" type="zigzag-purple" width="4rem" bottom="70%" left="80%"></v-shape>
        <v-shape v-if="!isMobile" type="cursor-purple" width="4rem" bottom="110%" left="25%"></v-shape>
        <v-shape v-if="!isMobile" type="square-hollow" width="4rem" bottom="110%" left="55%"></v-shape>
        <v-shape v-if="!isMobile" type="zigzag-yellow" width="4rem" top="90%" right="5%"></v-shape>
        <v-shape v-if="!isMobile" type="square" width="2rem" top="70%" left="17%"></v-shape>
        <v-shape v-if="!isMobile" type="square" width="1rem" top="115%" left="35%"></v-shape>

        <v-shape v-if="isMobile" type="square-hollow" top="-6%" left="-14%" width="25%"></v-shape>
        <v-shape v-if="isMobile" type="square" top="65%" left="-16%" width="10%"></v-shape>
        <v-shape v-if="isMobile" type="square" top="35%" left="-16%"></v-shape>
        <v-shape v-if="isMobile" type="square" top="35%" left="115%"></v-shape>
        <v-shape v-if="isMobile" type="zigzag-blue" top="10%" left="-60%" width="35%"></v-shape>
        <v-shape v-if="isMobile" type="cursor-purple" top="50%" left="-60%" width="20%" rotation="-80"></v-shape>
        <v-shape v-if="isMobile" type="cursor-purple" top="-3%" left="90%" width="20%"></v-shape>
        <v-shape v-if="isMobile" type="zigzag-purple" top="0%" left="135%" width="28%" rotation="15"></v-shape>
        <v-shape v-if="isMobile" type="zigzag-yellow" top="50%" left="135%" width="28%"></v-shape>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import Shape from '@/app/components/Shape';

    import { dottify } from '@/app/mixins';
    import { gsap } from '@/vendor';

    export default {
        components: {
            'v-shape': Shape
        },

        mixins: [ dottify ],

        data()
        {
            return {
                numDisplay: 0
            };
        },

        computed: {
            numRegistrations()
            {
                return this.$store.getters.registrations;
            },

            isMobile()
            {
                return this.$store.getters.isMobile;
            }
        },

        watch: {
            numRegistrations: function( pVal )
            {
                this.numDisplay = pVal;
            }
        },

        mounted()
        {
            this.inAnim();
            this.resizeRef = this.onResize;
            window.addEventListener( 'resize', this.resizeRef );
        },

        beforeUnmount()
        {
            window.removeEventListener( 'resize', this.resizeRef );
        },

        methods: {
            inAnim()
            {
                this.optelTl = gsap.timeline({ delay: 2 });
                this.optelTl.fromTo( this, { numDisplay: 0 }, { numDisplay: this.numRegistrations, roundProps: 'numDisplay', ease: 'expo.inOut', duration: 2 });
                return this.optelTl;
            },

            onResize()
            {
                if( !this.optelTl.isActive() && this.optelTl.progress() === 1 )
                {
                    this.numDisplay = this.numRegistrations;
                }
            }
        }
    };
</script>
