import { createStore } from 'vuex';

import entry from './modules/entry';
import webinar from './modules/webinar';

export default createStore({

    modules: {
        entry,
        webinar
    },

    state: {
        isMobile: window.innerWidth < 720,
        registrations: 0,
        heroVideoIsPlaying: false
    },

    getters: {
        isMobile: state => state.isMobile,
        registrations: state => state.registrations,
        heroVideoIsPlaying: state => state.heroVideoIsPlaying
    },

    mutations: {
        setMobile: ( state, payload ) =>
        {
            state.isMobile = payload;
        },

        initRegistrations: ( state, payload ) =>
        {
            state.registrations = payload;
        },

        addRegistration: state =>
        {
            state.registrations = state.registrations + 1;
        },

        heroVideoIsPlaying: ( state, payload ) =>
        {
            state.heroVideoIsPlaying = payload;
        }
    },

    actions: {
        setMobile: ( context, payload ) =>
        {
            context.commit( 'setMobile', payload );
        },

        initRegistrations: ( context, payload ) =>
        {
            context.commit( 'initRegistrations', payload );
        },

        addRegistration: context =>
        {
            console.log( 'adding registration' );
            context.commit( 'addRegistration' );
        },

        heroVideoIsPlaying: ( context, payload ) =>
        {
            context.commit( 'heroVideoIsPlaying', payload );
        }
    }
});
