<template>
    <div class="form-section">
        <div class="row">
            <div class="col-12">
                <div class="colorful-panel">
                    <div class="header-wrapper">
                        <h1 v-if="hasH1">Ik registreer mij voor het beweegminuut record!</h1>
                        <h2 v-else>Ik registreer mij voor het beweegminuut record!</h2>
                    </div>

                    <div class="content-wrapper">
                        <v-form v-if="!formCompleted" @formComplete="onFormComplete" ref="customForm"></v-form>

                        <!-- <p v-else class="text-center">Super dat je meedoet! Je bent een echte strijder. Plug dit alles ook even bij je collega's want samen bewegen is leuker!</p> -->
                        <button v-else class="complete-button">Aanmelding gelukt</button>
                    </div>

                    <v-shape v-if="!isMobile" type="zigzag-blue" top="-7%" left="3rem" width="4rem"></v-shape>
                    <v-shape v-if="!isMobile" type="square" top="6%" left="-1rem"></v-shape>
                    <v-shape v-if="!isMobile" type="circle" top="-10%" right="10%" width="3rem"></v-shape>
                    <v-shape v-if="!isMobile" type="circle-half" top="3%" right="-0.5%" width="3rem"></v-shape>
                    <v-shape v-if="!isMobile" type="triangle-circle" top="90%" left="20%" width="6rem"></v-shape>
                    <v-shape v-if="!isMobile" type="square-hollow" top="90%" left="70%" width="5rem"></v-shape>

                    <v-shape v-if="isMobile" type="square" top="5%" left="-1rem"></v-shape>
                    <v-shape v-if="isMobile" type="zigzag-blue" top="-5%" left="1rem" width="5rem" rotation="120"></v-shape>
                    <v-shape v-if="isMobile" type="circle" top="-7%" right="1rem" width="3rem"></v-shape>
                    <v-shape v-if="isMobile" type="square-hollow" top="90%" right="2rem" width="5rem"></v-shape>
                    <v-shape v-if="isMobile" type="triangle-circle" top="90%" left="2rem" width="5rem"></v-shape>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .complete-button{
        margin:2rem auto;
        display:block;
    }
</style>

<script>
    import Form from '@/app/components/Form';
    import Shape from '@/app/components/Shape';

    export default {
        components: {
            'v-form': Form,
            'v-shape': Shape
        },

        props: {
            hasH1: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            isMobile()
            {
                return this.$store.getters.isMobile;
            },

            formCompleted()
            {
                return this.$store.getters['entry/all'].id;
            }
        },

        methods: {
            onFormComplete()
            {
                this.$store.dispatch( 'addRegistration' );
            }
        }
    };
</script>
