const DOTTIFY_REGEX = /(\d+)(\d{3})/;

export default {
    methods: {
        dottify( pNumber )
        {
            while( DOTTIFY_REGEX.test( pNumber.toString() ) )
            {
                pNumber = pNumber.toString().replace( DOTTIFY_REGEX, '$1.$2' );
            }

            return pNumber;
        }
    }
};
