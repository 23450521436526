import { createApp } from 'vue';
import App from './App.vue';

import router from '@/app/router';
import store from '@/app/store';

store.dispatch( 'initRegistrations', parseInt( window._rootData.entries_num, 10 ) );

const app = createApp( App );
app.use( router );
app.use( store );

app.mount( '#app' );
