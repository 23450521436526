<template>
    <div class="socialpost-section">
        <div class="row">
            <div class="col-12 m-col-6 l-col-5">
                <h2>Laat zien dat je meedoet!</h2>
                <p>Laat zien dat jij meedoet met de Nationale Beweegminuut met het Nationale Beweegminuutfilter voor op sociale media. Hoe&nbsp;doe&nbsp;je&nbsp;dat?</p>

                <ol>
                    <li>Upload een foto van jezelf</li>
                    <li>Download je foto met filter voor op LinkedIn, Facebook, Instagram, Whatsapp of een ander sociaal medium.</li>
                </ol>

                <input class="hidden" type="file" ref="input" @change="handleData" />
                <button @click="uploadClick">Upload een afbeelding</button>

                <p>Je kan dit bericht gebruiken om je collega's te mobiliseren:</p>
                <p class="blue">&ldquo;Al dat langdurige zitten is niet goed voor ons. Daarom doe ik mee met de Nationale Beweegminuut: elke werkdag om 11:00 &eacute;&eacute;n minuut bewegen.<br/><br/>Check www.denationalebeweegminuut.nl en doe ook mee! #nationalebeweegminuut&rdquo;</p>

                <v-shape v-if="!isMobile" type="triangle-circle" width="4rem" top="0%" left="1%" rotation="0"></v-shape>
                <v-shape v-if="!isMobile" type="zigzag-blue" width="4rem" top="57%" left="5%" rotation="0"></v-shape>
                <v-shape v-if="!isMobile" type="square" width="2rem" top="100%" left="15%" rotation="0"></v-shape>
                <v-shape v-if="!isMobile" type="zigzag-pink" width="2rem" top="90%" left="50%" rotation="-230deg"></v-shape>

            </div>
            <div class="col-12 m-col-6 l-col-offset-1">
                <div class="photo-wrapper">
                    <img v-if="!hasUploadedImage" src="/static/images/socialpost/preview.png?v2" />
                    <canvas :class="{ 'hidden': !hasUploadedImage }" ref="canvas" :width="canvasW" :height="canvasH"></canvas>

                    <v-shape v-if="!isMobile" type="square-hollow" width="4rem" top="20%" left="1rem" rotation="0"></v-shape>
                </div>

                <div class="hidden">
                    <img ref="filter" src="/static/images/socialpost/filter.png" />
                    <div ref="preview"></div>
                </div>

                <a class="button" v-if="downloadLink" download="beweegminuut-social.jpg" :href="downloadLink" >Download jouw foto</a>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .hidden{
        position:absolute;
        opacity: 0;
        pointer-events:none;
    }
</style>

<script>
    import Shape from '@/app/components/Shape';

    export default {
        components: {
            'v-shape': Shape
        },

        data()
        {
            return {
                downloadLink: null,
                canvasW: 500,
                canvasH: 500,
                hasUploadedImage: false
            };
        },

        computed: {
            isMobile()
            {
                return this.$store.getters.isMobile;
            }
        },

        mounted()
        {
            this.ctx = this.$refs.canvas.getContext( '2d' );
        },

        methods: {
            uploadClick()
            {
                this.$refs.input.click();
            },

            handleData()
            {
                this.$refs.preview.innerHTML = '';

                const file = this.$refs.input.files[0];

                let img = document.createElement( 'img' );
                img.classList.add( 'obj' );
                img.file = file;
                this.$refs.preview.appendChild( img );

                const reader = new FileReader();

                reader.onload = e =>
                {
                    img.src = e.target.result;

                    this.$nextTick( () =>
                    {
                        let bounds = img.getBoundingClientRect();
                        console.log( bounds.width );
                        // width 100%
                        // this.ctx.drawImage( img, 0, 0, this.canvasW, bounds.height / bounds.width * this.canvasH );

                        //height 100%
                        let width = bounds.width / bounds.height * this.canvasW;
                        let factor = 1;
                        if( width < 500 )
                        {
                            factor = 500 / width;
                            width = 500;
                        }

                        let offset = -( width - this.canvasW ) / 2;
                        let hOffset = ( 500 - ( this.canvasH * factor ) ) / 2;

                        this.ctx.drawImage( img, offset, hOffset, width, this.canvasH * factor );
                        this.hasUploadedImage = true;
                        this.pasteFilter();
                    });
                };

                reader.readAsDataURL( file );
            },

            pasteFilter()
            {
                this.ctx.drawImage( this.$refs.filter, 0, 0, this.canvasW, this.canvasH );

                this.downloadLink = this.$refs.canvas.toDataURL();
            }
        }
    };
</script>
