<template>
    <div class="calendar-unit">
        <h2>Zet de beweegminuut in&nbsp;je&nbsp;agenda!</h2>
        <div class="icons">
            <a href="https://calendar.google.com/calendar/u/0/r/eventedit?dates=20220630T090000Z/20220630T090100Z&text=Beweegminuut&details=Voorbeeld&location=Office&allday=false&recur=RRULE%3AFREQ%3DWEEKLY%3BWKST%3DSU%3BBYDAY%3DMO%2CTU%2CWE%2CTH%2CFR" target="_blank" aria-label="Externe link: Google Calendar">
                <div class="white-square">
                    <img src="/static/images/icons/googlecalendar.svg" />
                </div>
                <p>Google <br/>Calendar</p>
            </a>

            <a href="https://outlook.live.com/calendar/0/deeplink/compose?rru=addevent&path=%2Fcalendar%2Faction%2Fcompose&startdt=2022-06-30T09%3A00%3A00Z&enddt=2022-06-30T09%3A01%3A00Z&subject=Beweegminuut&body=Voorbeeld&location=Office&allday=false" target="_blank" aria-label="Externe link: Outlook">
                <div class="white-square">
                    <img src="/static/images/icons/outlook.svg" />
                </div>
                <p>Outlook</p>
            </a>

            <a href="https://outlook.office.com/calendar/0/deeplink/compose?rru=addevent&path=%2Fcalendar%2Faction%2Fcompose&startdt=2022-06-30T09%3A00%3A00Z&enddt=2022-06-30T09%3A01%3A00Z&subject=Beweegminuut&body=Voorbeeld&location=Office&allday=false" target="_blank" aria-label="Externe link: Office 365" >
                <div class="white-square">
                    <img src="/static/images/icons/office.svg" />
                </div>
                <p>Office&nbsp;365</p>
            </a>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .calendar-unit{
        background: transparent url("/static/images/double-square.png") center no-repeat;
        background-size: contain;
        padding-top: 1px;
        padding-bottom: 2rem;
    }
    h2{
        color: blue;
        background: none;
        box-shadow:none;

        margin: 2rem auto;
    }
    .icons{
        display: flex;
        justify-content: space-between;
        max-width:375px;
        margin:0 auto;

        &>a{
            display:block;
            cursor:pointer;

            &:hover{
                .white-square{
                    transform: scale( 1.08 );
                }
            }
        }

        .white-square{
            position:relative;
            background-color:white;
            width:80px;
            margin:0 auto;
            margin-bottom:0.7rem;
            box-shadow: 4px 4px 0px blue;

            transition: transform 0.15s ease-out;
            will-change: transform;

            &:after{
                content:'';
                display:block;
                padding-bottom:100%;
            }

            img{
                position:absolute;
                width:60%;
                top:50%;
                left:50%;

                transform:translate(-50%, -50%);
            }
        }

        p{
            margin: 0;
            color:white;
            font-family: 'Uniform Extra Condensed';
            font-size:1.5rem;
            line-height:1;
            text-transform:uppercase;
        }
    }
</style>

<script>
    export default {

    };
</script>
