import * as yup from 'yup';

export default {
    beforeCreate()
    {
        yup.setLocale({
            mixed: {
                required: obj => `${obj.label ? obj.label : obj.path} is verplicht`, // `${obj.path} is default`,
                oneOf: () => `Actievoorwaarden zijn verplicht`
            },
            string: {
                min: obj => `${obj.path} moet minstens ${obj.min} karakters lang zijn`,
                matches: obj => `Dit is geen geldig ${obj.label}.`
            },
            number: {
                min: obj => `${obj.label} moet minstens ${obj.min} zijn`,
                max: obj => `${obj.label} mag maximaal ${obj.max} zijn`
            }
        });
    },

    data()
    {
        return {
            formConfig: {
                emailaddress: {
                    tabindex: 1,
                    label: 'E-mailadres',
                    name: 'emailaddress',
                    placeholder: 'Vul hier je e-mailadres in',
                    type: 'email'
                },

                sector: {
                    tabindex: 2,
                    name: 'sector',
                    placeholder: 'Selecteer sector',
                    options: [
                        {
                            name: 'ICT',
                            value: 'ICT'
                        },
                        {
                            name: 'Financiële dienstverlening',
                            value: 'Financiële dienstverlening'
                        },
                        {
                            name: 'Openbaar bestuur',
                            value: 'Openbaar bestuur'
                        },
                        {
                            name: 'Zakelijke dienstverlening',
                            value: 'Zakelijke dienstverlening'
                        },
                        {
                            name: 'Vervoer & opslag',
                            value: 'Vervoer & opslag'
                        },
                        {
                            name: 'Anders',
                            value: 'Anders'
                        }
                    ]
                },

                frequency: {
                    tabindex: 3,
                    name: 'frequency',
                    placeholder: 'Hoe vaak beweeg je tijdens het werk?',
                    options: [
                        {
                            name: 'Nooit',
                            value: 'Nooit'
                        },
                        {
                            name: 'Elke pauze',
                            value: 'Elke pauze'
                        },
                        {
                            name: 'Elk uur',
                            value: 'Elk uur'
                        },
                        {
                            name: 'Elk halfuur',
                            value: 'Elk halfuur'
                        }
                    ]
                },

                kit: {
                    tabindex: 6,
                    type: 'checkbox',
                    name: 'kit',
                    id: 'kit-checkbox'
                },

                kit2: {
                    tabindex: 4,
                    type: 'checkbox',
                    name: 'kit2',
                    id: 'kit2-checkbox'
                },

                agree: {
                    tabindex: 5,
                    type: 'checkbox',
                    name: 'agree',
                    id: 'agree-checkbox'
                }
            },

            validationSchema: yup.object().shape({
                emailaddress: yup.string()
                    .label( 'e-mail' )
                    .required( 'Emailadres is verplicht' )
                    .email( 'Dit is geen valide mailadres' ),

                sector: yup.string()
                    .label( 'sector' )
                    .required( 'Selecteer sector is verplicht' ),

                frequency: yup.string()
                    .label( 'hoeveelheid' )
                    .required( 'Selecteer interval is verplicht' ),

                agree: yup.boolean()
                    .label( 'voorwaarden' )
                    .oneOf( [true], 'Akkoord gaan met de voorwaarden is verplicht' )
            })
        };
    }
};
