export default {
    namespaced: true,

    state: {
        webinars: [
            {
                startdate: new Date( 'April 5, 2022 16:00:00' ),
                enddate: new Date( 'April 5, 2022 17:00:00' ),
                speaker: 'Lidewij Renaud (TNO)',
                about: 'Zittend kantoorwerk: inzicht in risico\'s en redding',
                url: 'https://www.arboportaal.nl/campagnes/zet-ook-de-stap/werken---bewegen-challenge/doe-mee-aan-de-nationale-beweegminuut/webinars'
            },
            {
                startdate: new Date( 'April 7, 2022 16:00:00' ),
                enddate: new Date( 'April 7, 2022 17:00:00' ),
                speaker: 'Anniek Boeijinga (Radboud)',
                about: 'Chauffeurs in beweging',
                url: 'https://www.arboportaal.nl/campagnes/zet-ook-de-stap/werken---bewegen-challenge/doe-mee-aan-de-nationale-beweegminuut/webinars'
            },
            {
                startdate: new Date( 'April 11, 2022 16:00:00' ),
                enddate: new Date( 'April 11, 2022 17:00:00' ),
                speaker: 'Sven van As (Duwtje)',
                about: 'Breng beweging in je werkdag',
                url: 'https://www.arboportaal.nl/campagnes/zet-ook-de-stap/werken---bewegen-challenge/doe-mee-aan-de-nationale-beweegminuut/webinars'
            },
            {
                startdate: new Date( 'April 14, 2022 16:00:00' ),
                enddate: new Date( 'April 14, 2022 17:00:00' ),
                speaker: 'Pien Ploumen en Jeanine Frunt (vhp human performance)',
                about: 'Hoe krijg je mensen in beweging? Een praktische aanpak met duurzaam resultaat.',
                url: 'https://www.arboportaal.nl/campagnes/zet-ook-de-stap/werken---bewegen-challenge/doe-mee-aan-de-nationale-beweegminuut/webinars'
            }
        ]
    },
    getters: {
        webinars: state => state.webinars
    },
    mutations: {},
    actions: {}
};
