<template>
    <div :class="{ 'shape': true, [type]: true }">
        <img alt="" role="presentation" aria-hidden="true" :src="'/static/images/shapes/' + type + '.svg'" />
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { gsap } from '@/vendor';

    export default {
        props: {
            type: {
                default: 'square',
                type: String
            },

            rotation: {
                default: '0',
                type: String
            },

            width: {
                default: '2rem',
                type: String
            },

            top: {
                default: 'auto',
                type: String
            },

            bottom: {
                default: 'auto',
                type: String
            },

            left: {
                default: 'auto',
                type: String
            },

            right: {
                default: 'auto',
                type: String
            },

            hide: {
                default: false,
                type: Boolean
            }
        },

        watch: {
            hide: function( pVal )
            {
                if( pVal )
                {
                    gsap.to( this.$el, { opacity: 0, delay: Math.random() * 1, duration: Math.random() * 0.5 + 0.3 });
                }
                else
                {
                    gsap.to( this.$el, { opacity: 1, delay: Math.random() * 1, duration: Math.random() * 0.5 + 0.3 });
                }
            }
        },

        mounted()
        {
            gsap.set( this.$el, {
                rotation: this.rotation,
                width: this.width,

                top: this.top,
                left: this.left,
                bottom: this.bottom,
                right: this.right
            });

            /*
                circle
                circle-half
                square
                square-hollow
                triangle-circle
                zigzag-pink
                zigzag-yellow
                zigzag-purple
                zigzag-blue
                cursor-purple
                cursor-blue
             */
        }
    };
</script>
